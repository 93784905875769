import { reaction, makeAutoObservable } from "mobx";
import { persist } from "mobx-persist";
import { Store } from "./Store";
import Cookies from "js-cookie";
import { ApiClient, GraphqlClient } from "~libs/api";
import { navigate } from "gatsby";
import { IDownloadTrackingForm } from "~views/shared/components/FileTrackingForm/FileTrackingForm";
import { isBrowser } from "~libs/utils";

export class AuthenticationStore {
  authenticationStoreHydrated: boolean = false;

  @persist
  jwtAuthToken: string = null;

  @persist
  jwtRefreshToken: string = null;

  @persist
  jwtAuthExpiration: number | null = null;

  internalCookieSet: boolean = false;

  timeout: NodeJS.Timeout = null;

  get userHasValidToken() {
    return !!this.jwtAuthToken;
  }

  constructor(private store: Store, private client: ApiClient, private graphqlClient: GraphqlClient) {
    makeAutoObservable(this);

    reaction(
      () => [this.jwtAuthToken, this.authenticationStoreHydrated],
      () => {
        this.client.setToken(this.jwtAuthToken);
        this.graphqlClient.setToken(this.jwtAuthToken);
      }
    );

    reaction(
      () => [this.jwtAuthExpiration],
      () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        if (!!this.jwtAuthExpiration) {
          this.timeout = setTimeout(() => {
            this.refreshAccessToken();
          }, TWO_HOURS_MS);
        }
      }
    );
  }

  onMount = async () => {
    if (typeof window !== undefined) {
      this.SSOLoginReturn();
      this.linkedInLoginReturn();
      if (this.jwtAuthToken) {
        await this.refreshAccessToken();
        if (this.jwtAuthToken) {
          await this.store.userStore.fetchCurrentUser();
        }
      } else {
        this.store.userStore.publicUser = true;
      }
    }

    window.addEventListener("focus", this.handleFocus);

    return true;
  };

  onUnmount = async () => {
    window.removeEventListener("focus", this.handleFocus);
  };

  handleFocus = () => {
    this.checkAccessToken();
  };

  checkAccessToken = () => {
    if (!!this.jwtAuthToken && this.jwtAuthExpiration <= formatExpirationDate(Date.now())) {
      this.refreshAccessToken();
    }
  };

  setHydrated = (hydrated: boolean) => {
    this.authenticationStoreHydrated = hydrated;
  };

  /**
   * Set the access token.
   * @param {string} token [The token to be saved.]
   */
  setAccessToken = (jwtAuthToken: string, jwtRefreshToken: string, jwtAuthExpiration: string) => {
    this.jwtAuthToken = jwtAuthToken;
    this.jwtRefreshToken = jwtRefreshToken;
    this.jwtAuthExpiration = parseInt(jwtAuthExpiration);
    Cookies.set("internalAccessJWT", jwtAuthToken);
    this.internalCookieSet = true;
  };

  /**
   * Remove the the user access token, refresh token and set the validation check to false.
   */
  removeAccessTokens = () => {
    this.jwtAuthToken = null;
    this.jwtRefreshToken = null;
    this.jwtAuthExpiration = null;
    Cookies.remove("internalAccessJWT");
    this.internalCookieSet = false;
  };

  refreshAccessToken = async () => {
    const response = await this.graphqlClient.refreshToken(this.jwtRefreshToken);
    if (response.ok) {
      const { data, errors } = await response.json();
      if (errors) {
        this.store.loginStore.handleLogout();
      } else {
        this.setToken(data.refreshJwtAuthToken.authToken, getNewExpiration());
      }
    } else {
      this.store.loginStore.handleLogout();
    }
  };

  setToken = (token: string, expiration: number) => {
    this.jwtAuthToken = token;
    this.jwtAuthExpiration = expiration;
    Cookies.set("internalAccessJWT", token);
    this.internalCookieSet = true;
  };

  hasTokenExpired = () => {
    return false;
  };

  getTokenFromQueryParams = params => {
    if (typeof window !== undefined) {
      if (params.has("jwtAuthToken") && params.has("jwtRefreshToken")) {
        this.jwtRefreshToken = params.get("jwtRefreshToken");
        this.jwtAuthToken = params.get("jwtAuthToken");
        this.jwtAuthExpiration = params.get("jwtAuthExpiration");
        // window.history.replaceState({}, document.title, window.location.pathname);
      }
    }
  };

  SSOLoginReturn = () => {
    if (typeof window !== undefined) {
      const params = new URLSearchParams(window.location.search);
      if (params.has("returnFromSSO")) {
        this.getTokenFromQueryParams(params);
        if (isBrowser()) {
          const redirectTo = window.localStorage.getItem("redirect_to");
          if (!!redirectTo && redirectTo !== "undefined") {
            window.localStorage.removeItem("redirect_to");
            navigate(redirectTo);
          } else {
            navigate("/account/discovery/");
          }
        }
      }
    }
  };

  linkedInLoginReturn = () => {
    if (typeof window !== undefined) {
      const params = new URLSearchParams(window.location.search);
      if (params.has("returnToAuthorProfile") && this.store.userStore.returnToAuthorPage) {
        const redirectTo = window.localStorage.getItem("redirect_to");
        if (!!redirectTo && redirectTo !== "undefined") {
          this.store.userStore.editAuthorModalOpen = true;
          window.localStorage.removeItem("redirect_to");
          navigate(redirectTo);
        } else {
          navigate("/");
        }
      }
    }
  };

  trackDocument = async (data: { databaseId: number } & IDownloadTrackingForm) => {
    try {
      await this.client.client.post("/api/v1/usage_tracking/document", JSON.stringify(data));
    } catch (e) {}
  };

  trackToolkit = async (data: { databaseId: number } & IDownloadTrackingForm) => {
    try {
      await this.client.client.post("/api/v1/usage_tracking/toolkit", JSON.stringify(data));
    } catch (e) {}
  };
}

const TWO_HOURS_MS = 2 * 60 * 60 * 1000;

function getNewExpiration() {
  return formatExpirationDate(getExpirationTimeStamp());
}

/**
 * Return current time + 2 hours
 */
function getExpirationTimeStamp() {
  return new Date(new Date().getTime() + TWO_HOURS_MS).getTime();
}

function formatExpirationDate(datestringMs: number) {
  return parseInt((datestringMs / 1000).toFixed(0));
}
